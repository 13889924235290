












import Vue from 'vue';
import VideoCategoryComponent from '@/components/app/videos/VideoCategory.component.vue';

export default Vue.extend({

  name: 'VideoCategory',

  components: {
    VideoCategoryComponent
  }

});
